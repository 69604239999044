<template>
  <UINavBar />

  <header
    class="header department-header"
    :style="`${
      createBgColor(department?.bg_color) +
      createBgImg(siteURL, department?.bg_img)
    }`"
  >
    <div class="p-container">
      <div
        class="department-header-wrapper m-auto flex flex-column lg:flex-row lg:gap-4 align-items-center justify-content-center"
      >
        <div class="department-header-img">
          <img
            v-if="department?.img"
            class="department-header-logo"
            :src="siteURL + department?.img"
          />
        </div>
        <div
          class="department-header-text"
          :style="`${createColor(department?.text_color)}`"
        >
          <h1
            :class="[
              'department-header-title text-center ',
              department?.img ? 'lg:text-left' : '',
            ]"
          >
            {{ department?.title }}
          </h1>
          <p
            class="p-0 m-0 department-header-description text-center"
            v-html="department?.text"
          ></p>
        </div>
      </div>
    </div>
  </header>
  <main v-if="herowsData.rows" class="content-section memory-book">
    <section class="memory-book-section">
      <div class="p-container">
        <div class="grid">
          <div
            v-for="hero in herowsData.rows"
            class="col-12 md:col-6 lg:col-4 xl:col-3"
            :key="hero.id"
          >
            <MemoryCard :hero="hero" />
          </div>
        </div>
      </div>
      <div class="p-5 text-center" v-show="showNextBtn">
        <button class="following-values-btn">
          <span class="following-values-btn-text"> Завантажити ще </span>
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import UINavBar from "@/components/public/ui/UINavBar.vue";
import MemoryCard from "@/components/public/content/MemoryCard.vue";

import filters from "@/config/filters.yml";
import types from "@/config/types.yml";

import { mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";
import { createBgColor, createBgImg, createColor } from "@/helpers/styles";
import { runScrollPaginator } from "@/helpers/paginator";

export default {
  name: "MemoryBookView",
  components: {
    UINavBar,
    MemoryCard,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
      filterByDepartmentURL: {
        field: filters.departaments.filterByURL,
        value: "memory_book",
      },
      sortPostByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      filterByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: types.post.page,
      },
      filterByDepartmentID: {
        field: filters.posts.filterByDepartmentID,
        value: 15,
      },
      filters: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  watch: {
    async "filters.page"() {
      await this.getHeroes();
    },
  },
  computed: {
    ...mapState({
      department: (state) => state.public_departments.department,
      herowsData: (state) => state.public_posts.pageData,
    }),

    showNextBtn() {
      return (
        this.herowsData.total_rows >
          this.herowsData.pages * this.herowsData.count &&
        this.herowsData.count === this.filters.pageSize
      );
    },
  },
  methods: {
    ...mapActions([
      "getPublicDepartmentData",
      "getPublicPostsData",
      "clearPublicPostsData",
    ]),

    async getHeroes() {
      try {
        await this.getPublicPostsData({
          departmentID: 15,
          postType: types.post.page,
          filters: this.filters,
        });
      } catch (e) {
        console.log(e);
      }
    },

    createBgColor,
    createBgImg,
    createColor,
  },
  async mounted() {
    try {
      await this.getPublicDepartmentData(
        createFilters({}, [this.filterByDepartmentURL])
      );

      this.filters = createFilters(this.filters, [
        this.filterByPostTypeID,
        this.sortPostByDate,
        this.filterPostByPublished,
        this.filterByDepartmentID,
      ]);

      await this.getHeroes();
    } catch {
      this.$router.push({ name: "404-page" });
    }

    runScrollPaginator("following-values-btn", () => this.filters.page++);
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
